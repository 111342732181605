export default [
  {
    label: 'Asset Name / Network',
    field: 'asset',
  },
  {
    label: 'Total, Asset',
    field: 'totalAsset',
  },
  {
    label: 'Total, USD',
    field: 'totalUsd',
  },
  {
    label: 'Today, Asset',
    field: 'todayAsset',
  },
  {
    label: 'Today, USD',
    field: 'todayUsd',
  },
]

export const revenueCeFi = [
  {
    label: 'Asset',
    field: 'asset',
  },
  {
    label: 'Fee amount',
    field: 'fee',
  },
  {
    label: 'Fee amount, USD',
    field: 'feeUsd',
  },
]

export const userActivityMetrics = [
  {
    label: 'Value name',
    field: 'value',
  },
  {
    label: 'Total',
    field: 'total',
  },
  {
    label: 'Today',
    field: 'today',
  },
]
