<template>
  <div>
    <AppTable
      class="dark"
      :rows="mergedRows.value"
      :columns="columns"
      :is-pagination="false"
      :is-loading="requestInProgress"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col
              class="ml-auto mb-1 mt-2 mt-100 pb-1"
              md="3"
              xl="3"
          >
            <div class="d-flex justify-content-end text-right">
              By period: <br>from {{ startWithdrawPeriod }} to {{ endWithdrawPeriod }}
            </div>
          </b-col>
        </b-row>
      </template>
      
    </AppTable>

    <AppTable
      :rows="withdrawalsByPeriod"
      :columns="revenueCeFi"
      :is-pagination="false"
      :is-loading="requestInProgress"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col
              class="mb-1"
              md="4"
              xl="4"
          >
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('form.label.startDate') }}</label>
              <b-form-datepicker
                  v-model="dateFrom"
                  :min="availableMinPeriodDayFrom"
                  :max="availableMaxPeriodDayFrom"
                  close-button
                  local="en"
                  :placeholder="$t('form.placeholder.minDate')"
                  reset-button
                  today-button
                  @input="search"
              />
            </b-form-group>
          </b-col>

          <b-col
              class="mb-1"
              md="4"
              xl="4"
          >
            <b-form-group>
              <label for="datepicker-placeholder">{{ $t('form.label.endDate') }}</label>
              <b-form-datepicker
                  v-model="dateTo"
                  :min="availableMinPeriodDayTo"
                  :max="availableMaxPeriodDayTo"
                  close-button
                  local="en"
                  :placeholder="$t('form.placeholder.maxDate')"
                  reset-button
                  today-button
                  @input="search"
              />
            </b-form-group>
          </b-col>

          <b-col class="mb-1 mt-auto pb-1" md="1" xl="1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon icon="SearchIcon" size="18" />
            </b-button>
          </b-col>

          <b-col
              class="ml-auto mb-1 mt-2 mt-100 pb-1"
              md="3"
              xl="3"
          >
            <div class="d-flex justify-content-end text-right">
              By period: <br>from {{ startPeriod }} to {{ endPeriod }}
            </div>
          </b-col>
        </b-row>
      </template>
    </AppTable>

    <AppTable
      :rows="transformedWithdrawAggregations"
      :columns="userActivityMetrics"
      :is-pagination="false"
      :is-loading="requestInProgress"
    />
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns, { revenueCeFi, userActivityMetrics } from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'CefiWithdrawView',
  components: {
    BFormDatepicker,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    AppTable,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],

  data() {
    return {
      columns,
      revenueCeFi,
      userActivityMetrics,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      dateTo: '',
      dateFrom: '',

      baseAggregations: null,
      aggregations: null,
      period: {
        min: null,
        max: null,
      },

      withdraws: {
        fee: [],
        total: {},
        todayTotal: {},
        period: {
          min: null,
          max: null,
        },
      },
      withdrawsByPeriod: {
        fee: [],
        total: {},
        today: {},
      },
      withdrawAggregations: {
        withdrawals: {
          total: 0,
          today: 0,
        },
        averageVolume: {
          total: 0,
          today: 0,
        },
        averageFee: {
          total: 0,
          today: 0,
        },
        onceWithdrawalUsersPercent: 0,
        repeatedonceWithdrawalUsersPercent: 0,
      },
    }
  },

  computed: {
    availableMinPeriodDayFrom() {
      return this.period.min
    },

    availableMaxPeriodDayFrom() {
      return this.dateTo || this.period.max
    },

    availableMinPeriodDayTo() {
      return this.dateFrom || this.period.min
    },

    availableMaxPeriodDayTo() {
      return this.period.max
    },

    startPeriod() {
      return this.dateFrom || this.period.min
    },

    endPeriod() {
      return this.dateTo || this.period.max
    },

    startWithdrawPeriod() {
      return new Date(this.period.min).toISOString()?.split('T')?.[0]
    },

    endWithdrawPeriod() {
      return new Date(this.period.max).toISOString()?.split('T')?.[0]
    },
    
    transformedWithdrawAggregations() {
      const { withdrawals, averageVolume, averageFee, onceWithdrawalUsersPercent, repeatedonceWithdrawalUsersPercent } =
        this.withdrawAggregations || {}
      return [
        {
          value: 'CeFi Withdrawals',
          total: withdrawals.total,
          today: withdrawals.today,
        },
        {
          value: 'Average Volume, USD',
          total: averageVolume.total,
          today: averageVolume.today,
        },
        {
          value: 'Average Fee, USD',
          total: averageFee.total,
          today: averageFee.today,
        },
        {
          value: 'CeFi withdrawal Users, %',
          total: onceWithdrawalUsersPercent,
          today: 'N/A',
        },
        {
          value: 'Repeated CeFi withdrawal Users, %',
          total: repeatedonceWithdrawalUsersPercent,
          today: 'N/A',
        },
      ]
    },

    mergedRows() {
      return {
        value: [...this.withdrawlsCefiDefi, ...this.footerRows],
      }
    },

    withdrawlsCefiDefi() {
      return (
        this.withdraws?.fees?.map(item => {
          return {
            asset: `${item.token} / ${item.network}`,
            totalAsset: this.$decimal(item.totalFee || 0).toDecimalPlaces(2).toFixed(2),
            totalUsd: this.$decimal(item.totalUsd || 0).toDecimalPlaces(2).toFixed(2),
            todayAsset: this.$decimal(item.todayFee || 0).toDecimalPlaces(2).toFixed(2),
            todayUsd: this.$decimal(item.todayUsd || 0).toDecimalPlaces(2).toFixed(2),
          }
        }) || []
      )
    },

    withdrawalsByPeriod() {
      return (
        this.withdrawsByPeriod?.fees?.map(item => {
          return {
            asset: `${item.token} / ${item.network}`,
            fee: this.$decimal(item.totalFee).toDecimalPlaces(2).toFixed(2),
            feeUsd: this.$decimal(item.totalUsd).toDecimalPlaces(2).toFixed(2),
          }
        }) || []
      )
    },

    footerRows() {
      const { todayTotal = {}, total = {} } = this.withdraws || {}

      return [
        {
          asset: 'Total',
          totalAsset: 'N/A',
          totalUsd: this.$decimal(total?.usd || 0).toDecimalPlaces(2).toFixed(2),
          todayAsset: 'N/A',
          todayUsd: this.$decimal(todayTotal?.usd || 0).toDecimalPlaces(2).toFixed(2),
        },
        {
          asset: 'Given to Referrer 1st lvl',
          totalAsset: 'N/A',
          totalUsd: this.$decimal(total?.toReferrerOneUsd || 0).toDecimalPlaces(2).toFixed(2),
          todayAsset: 'N/A',
          todayUsd: this.$decimal(todayTotal?.toReferrerOneUsd || 0).toDecimalPlaces(2).toFixed(2),
        },
        {
          asset: 'Given to Referrer 2nd lvl ',
          totalAsset: 'N/A',
          totalUsd: this.$decimal(total?.toReferrerTwoUsd || 0).toDecimalPlaces(2).toFixed(2),
          todayAsset: 'N/A',
          todayUsd: this.$decimal(todayTotal?.toReferrerTwoUsd || 0).toDecimalPlaces(2).toFixed(2),
        },
      ]
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchFeeAggregationsByPeriod: 'cefiFees/fetchFeeAggregationsByPeriod',
      fetchFeeAggregationsAllTime: 'cefiFees/fetchFeeAggregationsAllTime',
      fetchWithdrawsAggregations: 'dashboard/fetchWithdrawsAggregations',
    }),

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      
      
      this.fetchFeeAggregationsAllTime().then((response) => {
        this.withdraws = response?.data?.data;
      }).catch(this.checkErrors)
      
      this.fetchFeeAggregationsByPeriod(query).then((response) => {
        this.withdrawsByPeriod = response.data?.data || {}
        
        this.period = response.data?.data?.period
        let minDate = new Date(this.period?.from)
        minDate = minDate?.toISOString()?.split('T')?.[0]
        let maxDate = new Date(this.period?.to)
        maxDate = maxDate?.toISOString()?.split('T')?.[0]

        this.period = {
          min: minDate,
          max: maxDate,
        }
      }).catch(this.checkErrors)
      
      
      this.fetchWithdrawsAggregations(query)
          .then(response => {
            this.withdrawAggregations = response?.data
          })
          .catch(this.checkErrors)
    },

    createQueryConfig(config) {
      return {
        page: this.items?.page,
        limit: this.items?.limit,

        from: this.dateFrom?.replace(/-/g, '.') || null,
        to: this.dateTo?.replace(/-/g, '.') || null,

        ...config,
      }
    },

    search() {
      const query = this.createQueryConfig({
        page: 1,
      })

      return this.getWithdrawsByPeriod(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchStars(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchStars(query)
    },

    getWithdrawsByPeriod(query) {
      return this.waitRequest(() =>
        this.fetchFeeAggregationsByPeriod(query)
          .then(response => {
            this.withdrawsByPeriod = response.data?.data || {}
          })
          .catch(this.checkErrors),
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';

.coin-img {
  width: 30px;
  border-radius: 50%;
}

.dark {
  .vgt-table {
    tr:nth-last-child(-n + 3) {
      td {
        background-color: rgba(51, 68, 109, 0.03) !important;
      }
    }

    tr:nth-of-type(odd) {
      background-color: white !important;
    }
  }
}

.dark-layout {
  .dark {
    .vgt-table {
      tr:nth-last-child(-n + 3) {
        td {
          $theme-dark-card-bg-darker: #22293c !default;
          background-color: $theme-dark-card-bg-darker !important;
        }
      }
    }
  }
}
</style>
